<template>
  <div class="h-100 d-flex">
    <b-container class="bg-white flex-fill p-2">
      <router-link
        :to="{ name: 'account' }"
        class="btn btn-outline-primary btn-sm"
      >
        <font-awesome-icon icon="arrow-left" />
      </router-link>
      <b-form class="py-2" @submit.prevent="twilio">
        <b-form-group label="Mon numéro de téléphone" label-for="phone">
          <b-form-input
            id="phone"
            v-model="me.phone"
            type="text"
            required
            placeholder="Votre numéro de téléphone"
            :state="error && error['phone'] ? false : null"
          />
        </b-form-group>
        <b-form-invalid-feedback v-if="error.phone" :state="false">
          {{ error.phone }}
        </b-form-invalid-feedback>
        <b-btn
          type="submit"
          class="mt-2"
          variant="primary"
          block
          :disabled="loading || disable"
        >
          <loader v-if="loading" size="1x" />Valider
        </b-btn>
      </b-form>
      <b-modal id="verify" ref="verifyModalRef" hide-footer>
        <b-form-group label="Code" label-for="code">
          <b-form-input
            id="code"
            v-model="code"
            type="text"
            required
            placeholder="Code"
            :state="error && error['code'] ? false : null"
          />
          <b-form-invalid-feedback v-if="error.code" :state="false">
            {{ error.code }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-btn :disabled="loading || disable" @click="doVerify">
          Envoyer<loader v-if="loading" size="1x" />
        </b-btn>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import Profile from "@/mixins/profile"
import TWILIO_START from "@/graphql/TwilioStart.gql"
import TWILIO_VERIFY from "@/graphql/TwilioVerify.gql"
export default {
  mixins: [Profile],
  data: () => ({
    initialPhone: null,
    verify: false,
    code: null
  }),
  computed: {
    disable: function () {
      return null === this.code || this.code === ""
    }
  },
  created() {
    this.initialPhone = this.me.phone.replace(/[^0-9]/g, "")
  },
  methods: {
    twilio() {
      this.loading = true
      let data = {
        phone: this.me.phone
      }
      this.$apollo
        .query({
          query: TWILIO_START,
          variables: data
        })
        .then(response => {
          this.loading = false
          this.$refs.verifyModalRef.show()
        })
        .catch(error => {
          this.loading = false
          if (error.graphQLErrors) {
            let validation = _.first(error.graphQLErrors).validation
            if (validation) {
              this.error = _.chain(validation)
                .mapValues(e => _.first(e))
                .value()
            }
          }
          reject()
        })
    },
    onSubmit() {
      this.submit({
        phone: this.me.phone
      })
    },
    accountkit() {
      let phone = this.me.phone.replace(/[^0-9]/g, "")
      if (phone !== this.initialPhone) {
        this.processing = true
        window.AccountKit.login(
          "PHONE",
          { countryCode: "+33", phoneNumber: phone.replace("+", "") }, // will use default values if not specified
          this.loginCallback
        )
      } else {
        this.submit()
      }
    },
    loginCallback(response) {
      if (response.status === "PARTIALLY_AUTHENTICATED") {
        this.onSubmit()
      } else if (response.status === "NOT_AUTHENTICATED") {
        this.error["phone"] =
          "Une erreur est survenue lors de la validaion de votre numéro de téléphone"
      }
    },
    doVerify() {
      this.loading = true
      let data = {
        phone: this.me.phone,
        code: this.code
      }
      this.$apollo
        .query({
          query: TWILIO_VERIFY,
          variables: data
        })
        .then(response => {
          if (response.data && response.data.twilioVerify) {
            this.onSubmit()
          } else {
            this.error["code"] = "Code invalide"
          }
        })
        .catch(error => {
          this.loading = false
          if (
            error.graphQLErrors &&
            error.graphQLErrors.length > 0 &&
            error.graphQLErrors[0].message === "INVALID_CODE"
          ) {
            this.error["code"] = "Code invalide"
          } else {
            this.error["code"] = "Une erreur est survenue"
          }
        })
        .finally(response => {
          this.loading = false
        })
    }
  }
}
</script>
